const usePrizeDropInit = () => {
	const { t } = useT();
	const { open } = useAppModals();

	const { resetPrizeDropTimer, lastWinners, durationFinished, toNextPrizeDurationLeft, toNextPrizeDuration } =
		usePrizeDrop();
	provide("prizeDrop", {
		resetPrizeDropTimer,
		lastWinners,
		durationFinished,
		toNextPrizeDurationLeft,
		toNextPrizeDuration
	});
	const { showTimeToast } = usePrizeDropsToasts();

	onMounted(() => {
		usePrizeDropSocket({
			t,
			open,
			showTimeToast,
			toNextPrizeDurationLeft,
			resetTimer: resetPrizeDropTimer
		});
	});
};

export default usePrizeDropInit;
