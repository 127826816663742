import type { v1, ExtractFromAPI, Parameters } from "@netgame/openapi";

export type InitDataResponse = ExtractFromAPI<v1.paths, "/rest/app/init/", "get">;
export type InitDataResponseData = InitDataResponse["data"];
export type InitDataResponseNonNullable = NonNullable<InitDataResponse["data"]>;
export type InitPresetPackage = NonNullable<InitDataResponseNonNullable["popupPresetPackage"]>;
export type LastGames = NonNullable<InitDataResponseNonNullable["lastGames"]>;

export type RegisterCompleteResponse = ExtractFromAPI<v1.paths, "/rest/players/register/complete/", "post">;
export type RegisterCompleteParams = Parameters<v1.paths["/rest/players/register/complete/"]["post"]>;

export type AllGamesResponse = ExtractFromAPI<v1.paths, "/rest/page/issues/all-games/", "get">;
export type GamesPayload = NonNullable<AllGamesResponse["payload"]>;
export type Games = GamesPayload["games"];
export type GamesMenu = GamesPayload["menu"];
export type Game = NonNullable<Games>[number];
export type GameModes = "SweepStakes" | "TournamentPoints";

export type PromotionsResponse = ExtractFromAPI<v1.paths, "/rest/page/promotions/", "get">;
export type PromotionsPayload = NonNullable<PromotionsResponse["payload"]>;
export type Promotions = PromotionsPayload["promotions"];
export type Promo = NonNullable<Promotions>[number];
export type PromoData = NonNullable<Promo["data"]>;
export type Promotion = {
	available?: boolean;
	skeleton?: boolean;
	type?:
		| "invite"
		| "tournament"
		| "verification"
		| "dailyWheel"
		| "inviteQualification"
		| "emailLottery"
		| "offerDeposit"
		| "offerBirthday"
		| "promoOfferConstructor"
		| "offerPeriodic"
		| "partnerReferralQualification"
		| "rushHourOffers"
		| "quest"
		| "bingo"
		| "offerDate"
		| "magicBox"
		| "piggyBank"
		| "season"
		| "flipTheCoin"
		| "offerPersonal"
		| "rankLeagueCashback"
		| "playerFreeSpin"
		| "depositStreak";
	data?: PromoData;
};
export type PromoPlayerFreeSpin = {
	enabled: boolean;
	spinsRemain: number;
	spinsTotal: number;
	betLevel: number;
	gameId: number;
	id: number;
};

export type InviteFriendsResponse = ExtractFromAPI<v1.paths, "/rest/page/referrals/", "get">;
export type InviteFriendsPayload = NonNullable<InviteFriendsResponse["payload"]>;
export type ReferralInfo = NonNullable<InviteFriendsPayload["referralInfo"]>;
export type RefPlayers = NonNullable<ReferralInfo["invitedFriends"]>;
export type RefPlayer = NonNullable<RefPlayers>[number];

export type PromoLoginResponse = ExtractFromAPI<v1.paths, "/rest/player/promo/login/", "get">;
export type PromoLogin = NonNullable<PromoLoginResponse["data"]>;

export type ProfilePageResponse = Required<ExtractFromAPI<v1.paths, "/rest/page/profile/", "get">>;
export type ProfileFileResponse = ExtractFromAPI<v1.paths, "/rest/player/documents/", "get">;
export type ProfileFile = {
	status?: string;
	name?: string;
	size?: number;
	sizeError?: boolean;
	data?: File;
} & ProfileFileResponse[number];

export type RegisterV2Response = ExtractFromAPI<v1.paths, "/rest/registerV2/", "post">;
export type RegisterV2Params = Parameters<v1.paths["/rest/registerV2/"]["post"]>;

export type LoginResponse = ExtractFromAPI<v1.paths, "/rest/login/", "post">;

export type CampaignsResponse = ExtractFromAPI<v1.paths, "/rest/page/affiliate/campaigns/", "post">;
export type CampaignsData = NonNullable<CampaignsResponse["campaigns"]>;

export type PartnerResponse = ExtractFromAPI<v1.paths, "/rest/page/affiliate/partner/", "post">;
export type Campaigns = NonNullable<PartnerResponse["campaignsForFilter"]>;
export type PartnerData = NonNullable<PartnerResponse["partner"]>;

export type PartnerReferralsResponse = ExtractFromAPI<v1.paths, "/rest/page/affiliate/partnerReferrals/", "post">;
export type PartnerReferralsCampaigns = NonNullable<PartnerReferralsResponse["campaignsForFilter"]>;
export type PartnerReferralsData = NonNullable<PartnerReferralsResponse["partnerReferrals"]>;

export type PlayersActivityResponse = ExtractFromAPI<v1.paths, "/rest/players-activity/", "get">;
export type PlayersActivityArray = NonNullable<PlayersActivityResponse["data"]>;
export type PlayersActivity = PlayersActivityArray[number];
export type PlayersActivityGame = PlayersActivity["data"];

export type ScratchCardsInfoData = ExtractFromAPI<v1.paths, "/rest/scratch-card-lottery/get/", "get">;
export type ScratchCardsTicketPlayData = ExtractFromAPI<v1.paths, "/rest/scratch-card/prize/resolve/", "post">;
export type ScratchCardsTypes = "bronze" | "silver" | "gold";

export type StatusLeaguePageResponse = ExtractFromAPI<v1.paths, "/rest/page/rank-league-info/", "get">;
export type StatusLeaguePagePayload = NonNullable<StatusLeaguePageResponse["payload"]>;
export type StatusLevels = NonNullable<StatusLeaguePagePayload["levels"]>;
export type CurrentProgress = NonNullable<StatusLeaguePagePayload["currentProgress"]>;
export type CurrentLevel = NonNullable<CurrentProgress["currentLevel"]>;
export type StatusSections = NonNullable<StatusLeaguePagePayload["sections"]>;
export type StatusSection = NonNullable<StatusSections>[number];

export type RankLeague = InitDataResponseNonNullable["rankLeague"];
export type CurrentSection = NonNullable<RankLeague>["currentSection"];

export type GameLastWinner = {
	type: "win" | "bigwin";
	gameMode: "TournamentPoints" | "SweepStakes";
	data: { winAmount: number; big_win_coefficient: number; id: number };
};

export type WinData = {
	coins: number;
	entries: number;
	number: number;
};

export type Badge = {
	percent: string;
	text: string;
	labelText?: string;
	labelTextColor?: string;
	labelBackground?: string;
	offerBackground?: string;
	usualTextColor?: string;
	promoUsualTextColor?: string;
};

export type EventData<TData> = {
	type: string;
	action: string;
	data: TData;
};
export type BalanceUpdate = {
	balance: string;
	bonus: Array<Record<string, unknown>>;
	bonusV2: string;
	cashUrl: string;
	cashbackAmount: number;
	compPoints: string;
	compPointsTotal: string;
	currency: string;
	entries: number;
	event: string;
	freespins_remain: number;
	leftRoleplay: number;
	roleplayLeftInPercent: number;
	roleplaying: string;
	tourPoints: number;
	winnings: string;
};
export type Social = {
	id: string;
	name: string;
	href: string;
	icon: string;
};

export type Contacts = {
	name: string;
	type: keyof NonNullable<InitDataResponseData>["registrationFormData"];
};

export type UserData = {
	login: string;
	password: string;
};

export type RegisterData = {
	contact: string;
	password: string;
	registration_source: string | undefined;
	traffic_source: string | undefined;
	agree: boolean;
	timezone: string;
	refcode?: string;
};

export type ReferralCode = {
	refcode: string;
};

export type AuthContext = {
	authErrorMessage?: string;
};

export type ContactsDictionary = {
	email: string;
	phone: string;
};

export type AuthSourceData = {
	registration_source: string | undefined;
	traffic_source: string | undefined;
};

export type Column = {
	headerName: string;
	field: string;
	class?: string;
	width: number;
};

export type Pagination = {
	current: number | null;
	pages: number | null;
};

export type Row = {
	[key: string]: string | number;
};

export type HomeResponse = ExtractFromAPI<v1.paths, "/rest/page/home/", "get">;
export type HomePayload = NonNullable<HomeResponse["payload"]>;
export type BannerPresetPackage = NonNullable<InitDataResponseData>["bannerPresetPackage"];
export type HomeBanners = NonNullable<HomePayload["banners"]>;
export type FirstScreenBanners = NonNullable<HomePayload["banners"]> & BannerPresetPackage;
export type HomeGames = NonNullable<HomePayload["games"]>;

export type HomeJackpots = NonNullable<HomePayload["jackpots"]>;

export enum EInsufficientFunds {
	TOURNAMENT_WITH_OFFER,
	TOURNAMENT_WITH_WINNINGS,
	SWEEPSTAKES_WITH_OFFER,
	NO_OFFER,
	SWEEPSTAKES_WITH_WINNINGS
}

export type BalanceResponse = ExtractFromAPI<v1.paths, "/rest/player/balance/", "get">;

export interface ConversionItem {
	key: string;
	label?: string;
	value: number;
}

export type WheelOfTreasureInfoData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/info/", "get">;
export type WheelOfTreasureWheelData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/prizes/", "get">;
export type WheelOfTreasure = WheelOfTreasureInfoData & { progress: boolean[] } & { canWheel?: boolean };
export type WheelOfTreasurePercentData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/wheels-prizes/", "get">;
export type WheelOfTreasureApplyPrizeData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/apply-prize/", "get">;

export type BonusesResponse = ExtractFromAPI<v1.paths, "/rest/bonus-offer/get-bonuses/", "get">;
export type ReferralInfoResponse = ExtractFromAPI<v1.paths, "/rest/page/referral-info/", "get">;

export type TournamentResponse = ExtractFromAPI<v1.paths, "/rest/tournament/", "get">;
export type TournamentData = NonNullable<TournamentResponse["data"]>;
export type TournamentPrizes = NonNullable<TournamentData["prizes"]>;
export type TournamentDataWinners = NonNullable<TournamentData["winners"]>;
export type TournamentPlayerData = NonNullable<TournamentResponse["playerData"]>;
export type TournamentWinner = NonNullable<TournamentDataWinners[number]>;
export type Tournament = NonNullable<TournamentData>;

export type NextTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/next-tournaments/", "get">;
export type NextTournamentsData = NonNullable<NextTournamentsResponse["tournaments"]>;
export type PrevTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/tournaments/history/", "get">;
export type PrevTournamentsData = NonNullable<PrevTournamentsResponse["tournaments"]>;
export type NextTournaments = NextTournamentsData[number];
export type NextTournamentsPrizes = NonNullable<NextTournaments["prizes"]>;
export type PrevTournaments = PrevTournamentsData[number];
export type PrevTournamentsPrizes = NonNullable<PrevTournaments["prizes"]>;
export type ExtendWinners = TournamentDataWinners & PrevTournaments & NextTournaments;
export type TournamentsPrizes = TournamentPrizes & PrevTournamentsPrizes & NextTournamentsPrizes;
export type WinnersResponse = ExtractFromAPI<v1.paths, "/rest/tournament/{id}/winners/", "get">;
export type WinnersData = NonNullable<WinnersResponse["data"]>;
export type Winners = NonNullable<WinnersData["winners"]>;

export type SeasonsResponse = ExtractFromAPI<v1.paths, "/rest/seasons/season/", "get">;
export type SeasonsLevels = NonNullable<SeasonsResponse["levels"]>;
export type SeasonsLevel = SeasonsLevels[number];

export type DetailInvite = {
	type: string;
	action: string;
	coins: number;
	entries: number;
};

export type PrizeDropsData = ExtractFromAPI<v1.paths, "/rest/prize-drops/info/", "get">;
export type LastWinners = NonNullable<PrizeDropsData["lastWinners"]>;
export type LastWinner = LastWinners[number];

export type PrizeDropsSocket = {
	playerId: string;
	playerName: string;
	prizeValue: string;
	prizeLevel: string;
	board: {
		prizes: [
			{
				prizeValue: number;
				prizeLeft: number;
			}
		];
		lastWinners: [
			{
				playerName: string;
				prizeValue: number;
			}
		];
	};
};

export type RewardSection = {
	id: number;
	title: string;
	weeklyReward: number;
};

export type RewardNotice = {
	type: "contacts" | "profile";
	action: "profileComplete" | "confirmPhone" | "confirmEmail";
	reward: { coins: number };
};
