import useStatus from "./useStatus";

type SocketStatusData = {
	currentSection: {
		id: number;
		title: string;
		benefits: string[];
	};
	currentLevel: {
		id: number;
		title: string;
		index?: number;
		currentExperience: number;
		neededExperience: number;
		prize: {
			coins: number;
			entries: number;
		};
	};
	nextSectionBenefits?: string[];
	coinsBack?: number;
	nextLevel: {
		id: number;
		title: string;
	};
	progressBlockedReason: null | string;
	progressData: {
		currentSection: {
			id: number;
			title: string;
			wheelMultiplayer: number;
		};
		currentLevel: {
			id: number;
			title: string;
			currentExperience: number;
			neededExperience: number;
			benefits: string[];
		};
	};
	status: "available" | "waiting";
	availableAfterDateTime: null | string;
	availableAfterDay: string;
	playerFullCompletedLeague: boolean;
	sumAllLevelsPoints: number;
	coinsWithMultiplier?: number;
	coinsWithoutMultiplier?: number;
	rankLeagueWheelMultiplier: number;
	entriesWithoutMultiplier?: number;
	entriesWithMultiplier?: number;
};

const useStatusLeagueSockets = () => {
	const { open } = useAppModals();
	const { data: appInitData } = useAppInitData();
	const { refresh: refreshHomePage } = useHomePage();
	const { refresh: refreshPromotionsData } = usePromotionsData();
	const { add } = useSockets();
	const { updateCashback } = useStatus();

	const statusUp = (data: SocketStatusData) => {
		const { rankLeague } = appInitData.value || {};

		if (!rankLeague) {
			return;
		}

		open("LazyOModalVipClubStatusUp", {
			section: data?.currentSection,
			prize: data?.currentLevel?.prize,
			benefits: data?.currentSection?.benefits,
			weeklyReward: data?.coinsBack,
			progressBlockedReason: !!data?.progressBlockedReason,
			playerFullCompletedLeague: data?.playerFullCompletedLeague
		});
		rankLeague.currentSection = data?.currentSection;
		rankLeague.currentLevel = data?.currentLevel;
		rankLeague.nextLevel = data?.nextLevel;
		rankLeague.nextSectionBenefits = data?.nextSectionBenefits;
		rankLeague.coinsBack = data?.coinsBack;
		rankLeague.playerFullCompletedLeague = data?.playerFullCompletedLeague;
		rankLeague.sumAllLevelsPoints = data?.sumAllLevelsPoints;
		refreshHomePage();
		refreshPromotionsData();
	};

	add("rankLeague", ({ action, data }: EventData<SocketStatusData>) => {
		const { rankLeague } = appInitData.value || {};

		if (!rankLeague?.enabled) {
			return;
		}

		if (action === "progressData") {
			if (rankLeague.currentSection) {
				rankLeague.currentSection.wheelMultiplayer = data?.progressData?.currentSection?.wheelMultiplayer;
			}
			if (
				data?.progressData?.currentLevel?.currentExperience &&
				(rankLeague?.currentLevel?.currentExperience || rankLeague?.currentLevel?.currentExperience === 0)
			) {
				rankLeague.currentLevel.currentExperience = data?.progressData?.currentLevel?.currentExperience;
			}
		}

		if (action === "sectionUp") {
			rankLeague.progressBlockedReason = data?.progressBlockedReason;
			statusUp(data);
		}

		if (action === "rankLeagueCashbackCalculated" || action === "rankLeagueCashbackExpired") {
			updateCashback({
				status: data?.status,
				availableAfterDateTime: data?.availableAfterDateTime,
				availableAfterDay: data?.availableAfterDay
			});
		}

		if (action === "rankLeagueDailyWheelPrize" && rankLeague) {
			rankLeague.rankLeagueDailyWheelPrize = data;
		}
	});
};

export default useStatusLeagueSockets;
